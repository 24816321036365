import React, { useState, useCallback } from "react";
import Box from "@mui/material/Box";
import { BarChart } from '@mui/x-charts/BarChart';
import { Typography, TextField, Button, ButtonGroup, useTheme } from "@mui/material";
import { getUsage, getDailyInfoUsageForUser } from '../../api/helpers';
import { tokens } from "../../Theme";

const chartSetting = {
  width: 1000,
  height: 500,
};

const valueFormatter = (value: number | null) => `${value}`;

export function SearchUserUsage() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [email, setEmail] = useState('');
  const [chartData, setChartData] = useState<{ screen: string, count: number }[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [viewMode, setViewMode] = useState<'all' | 'daily'>('all'); 
  const [timeRange, setTimeRange] = useState('last_24_hours');
  const [originalData, setOriginalData] = useState<{ screen: string, count: number }[]>([]); 
  const [dataFetched, setDataFetched] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      let data: { screen: string, count: number }[] = [];
      setDataFetched(false); 

      const page_num = 1;
      const response = await getUsage(email, page_num);
      const usageData = response?.usages || [];

      if (usageData.length === 0) {
        setChartData([]);
        setErrorMessage("No user found! Please enter an existing email or try after refreshing the page");
        return;
      }

      const screenCounts: { [key: string]: number } = {};

      usageData.forEach((usage: any) => {
        const screenName = usage.currentScreen;
        if (screenCounts[screenName]) {
          screenCounts[screenName]++;
        } else {
          screenCounts[screenName] = 1;
        }
      });

      data = Object.keys(screenCounts).map(screen => ({
        screen,
        count: screenCounts[screen],
      }));

      setOriginalData(data); 
      setChartData(data); 
      setErrorMessage(''); 
      setDataFetched(true); 
    } catch (error) {
      console.error("Error fetching usage data:", error);
      setErrorMessage("An error occurred while fetching data.");
    }
  }, [email]);

  const handleSearch = () => {
    setViewMode('all'); 
    fetchData();
  };

  const handleViewModeChange = async (newViewMode: 'all' | 'daily') => {
    setViewMode(newViewMode);
    filterData(newViewMode, timeRange);
  };

  const handleTimeRangeChange = (selectedTimeRange: string) => {
    setTimeRange(selectedTimeRange);
    filterData(viewMode, selectedTimeRange);
  };

  const filterData = async (newViewMode: 'all' | 'daily', selectedTimeRange: string) => {
    if (newViewMode === 'daily') {
      const response = await getDailyInfoUsageForUser(selectedTimeRange, email);
      const screenCounts = response || {};

      const dailyData = [
        { screen: 'nutrition', count: screenCounts?.nutrition || 0 },
        { screen: 'recovery', count: screenCounts?.recovery || 0 },
        { screen: 'movement', count: screenCounts?.movement || 0 },
        { screen: 'cognition', count: screenCounts?.cognition || 0 },
        { screen: 'connection', count: screenCounts?.connection || 0 },
        { screen: 'aesthetics', count: screenCounts?.aesthetics || 0 },
      ];
      setChartData(dailyData);
    } else {
      setChartData(originalData); // Revert to original data for 'all' view
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography variant="h4" align="center" gutterBottom>
        SEARCH SCREEN USAGE OF A USER BY EMAIL
      </Typography>
      <Box sx={{ display: "flex", gap: 2, mb: 2, mt: 2 }}>
        <TextField 
          label="Enter Email" 
          variant="outlined" 
          value={email} 
          onChange={(e) => setEmail(e.target.value)} 
        />
        <Button 
          variant="contained" 
          onClick={handleSearch} 
          sx={{ backgroundColor: colors.greenAccent[500], color: colors.grey[100], '&:hover': { backgroundColor: colors.greenAccent[600] } }}
        >
          Search
        </Button>
      </Box>

      {dataFetched && (
        <>
          <ButtonGroup variant="contained" sx={{ mb: 2 }}>
            <Button
              onClick={() => handleViewModeChange('all')}
              sx={{
                backgroundColor: viewMode === 'all' ? colors.greenAccent[500] : colors.greenAccent[600],
                color: colors.grey[100],
                '&:hover': { backgroundColor: colors.greenAccent[500] },
              }}
            >
              All Views
            </Button>
            <Button
              onClick={() => handleViewModeChange('daily')}
              sx={{
                backgroundColor: viewMode === 'daily' ? colors.greenAccent[500] : colors.greenAccent[600],
                color: colors.grey[100],
                '&:hover': { backgroundColor: colors.greenAccent[500] },
              }}
            >
              Daily Info
            </Button>
          </ButtonGroup>

          {viewMode === 'daily' && (
            <ButtonGroup variant="contained" aria-label="time range button group" sx={{ mb: 2 }}>
              <Button
                onClick={() => handleTimeRangeChange('last_24_hours')}
                sx={{
                  backgroundColor: timeRange === 'last_24_hours' ? colors.greenAccent[500] : colors.greenAccent[600],
                  color: colors.grey[100],
                  '&:hover': { backgroundColor: colors.greenAccent[500] },
                }}
              >
                Last 24 Hours
              </Button>
              <Button
                onClick={() => handleTimeRangeChange('this_week')}
                sx={{
                  backgroundColor: timeRange === 'this_week' ? colors.greenAccent[500] : colors.greenAccent[600],
                  color: colors.grey[100],
                  '&:hover': { backgroundColor: colors.greenAccent[500] },
                }}
              >
                This Week
              </Button>
              <Button
                onClick={() => handleTimeRangeChange('this_month')}
                sx={{
                  backgroundColor: timeRange === 'this_month' ? colors.greenAccent[500] : colors.greenAccent[600],
                  color: colors.grey[100],
                  '&:hover': { backgroundColor: colors.greenAccent[500] },
                }}
              >
                This Month
              </Button>
            </ButtonGroup>
          )}
        </>
      )}

      {errorMessage ? (
        <Typography variant="h6" color="error" align="center">
          {errorMessage}
        </Typography>
      ) : (
        <BarChart
          dataset={chartData}
          xAxis={[{ scaleType: 'band', dataKey: 'screen', label: 'SCREENS' }]}
          yAxis={[{ label: 'No. of times visited' }]}
          series={[{ dataKey: 'count', label: 'No. of times Visited', valueFormatter }]}
          layout="vertical"
          {...chartSetting}
        />
      )}
    </Box>
  );
}

export default SearchUserUsage;
