import React, { useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import { BarChart } from '@mui/x-charts/BarChart';
import { Typography } from "@mui/material";
import { getCurrentUserCount, getNewUsersCount, getReturnUserCount } from '../api/helpers'; // Combined imports

const chartSetting = {
  width: 500,
  height: 300,
};

const valueFormatter = (value: number | null) => `${value}`;

export function ReturningUsers() {
  const [data, setData] = useState([
    { screen: 'Total Users', count: 0 },
    { screen: 'Returning Users', count: 0 },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [currentUsersResponse, newUsersResponse, returningUsersResponse] = await Promise.all([
          getCurrentUserCount(),
          getNewUsersCount(),
          getReturnUserCount(),
        ]);

        const currentUserCount = currentUsersResponse.count; // Adjust based on actual response structure
        const newUserCount = newUsersResponse.newUsersCount; // Adjust based on actual response structure
        const returnUserCount = returningUsersResponse.return_user_count;

        setData(prevData => prevData.map(d => {
          if (d.screen === 'Total Users') {
            return { ...d, count: currentUserCount };
          } else if (d.screen === 'New Users') {
            return { ...d, count: newUserCount };
          } else if (d.screen === 'Returning Users') {
            return { ...d, count: returnUserCount };
          } else {
            return d;
          }
        }));
      } catch (error) {
        console.error('Failed to fetch home screen usage count:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", }}>
      <Typography variant="h4" align="left" gutterBottom>
        Total vs Returning Users (This Month)
      </Typography>
      <BarChart
        dataset={data}
        xAxis={[{ scaleType: 'band', dataKey: 'screen', label: 'SCREENS' }]}
        yAxis={[{ label: 'USERS' }]}
        series={[{ dataKey: 'count', label: 'USERS', valueFormatter }]}
        layout="vertical"
        {...chartSetting}
      />
    </Box>
  );
}

export default ReturningUsers;
