import { Box, Container, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { isAuthenticated, verifyToken } from '../../auth/helpers';
import {getUsage, getUsers,} from '../../api/helpers';
import { Navigate } from 'react-router-dom';
import { UsageObject, UserType } from '../../Types';
import UsageData from '../../components/usageData';
import UserList from '../../components/userList';
import { GradientCircularProgress } from '../../components/loadingComponents';

type UsageObjectSetter = React.Dispatch<React.SetStateAction<UsageObject | null>>;
type UserObjectSetter = React.Dispatch<React.SetStateAction<UserType[]>>;

const loadUsageItems = async (email: string, pageNum: number, setUsageItems: UsageObjectSetter) => {
    const data = await getUsage(email, pageNum);
    if (!data) {
        return;
    }
    setUsageItems((prev) => {
        return {
            ...prev,
            [email]: data,
        };
    });
}

const loadUsers = async (pageNum: number, setUsers: UserObjectSetter) => {
    const data = await getUsers(pageNum);
    if (!data) {
        return;
    }
    console.log('page:', pageNum, 'data:', data);
    const users = data['users']
    // currently we are not doing pagination so total_pages is not used
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const total_pages = data['total_pages']
    // no need to parse the data because it's already in the correct format
    setUsers((prev) => {
        return [...prev, ...users];
    });
}

export const Users = () => {
    // TODO: Move the dashboard part out of the token stuff
    // TODO: 
    // 1. Retrieve just the users
    // 2. On user select, retrieve the data for that user but only first 100 items
    // 3. On scroll, retrieve the next 100 items
    // 4. TODO: Color code the cards based on the usage values
    const initialized = useRef(false);
    const [redirect, setRedirect] = useState(false);
    const [usageResponse, setUsageResponse] = useState<UsageObject | null>(null);
    const [users, setUsers] = useState<UserType[]>([]);
    const [currentPageNum, setCurrentPageNum] = useState(1);
    const [userDataLoading, setUserDataLoading] = useState(false);
    useEffect(() => {
        if (currentPageNum === 1) {
            return;
        }
        loadUsers(currentPageNum, setUsers);
    }, [currentPageNum]);
    useEffect(() => {
        // check if verification is done already
        if (initialized.current) {
            return;
        }
        initialized.current = true;
        // check if auth token is stored locally
        const authStatus = isAuthenticated();
        if (!authStatus) {
            setRedirect(true);
            return;
        }

        // verify token with the server
        console.log('Checking token...');
        verifyToken()
        .then((status) => {
            if (status) {
                loadUsers(1, setUsers);
            } else {
                setRedirect(true);
            }
        })
        .catch((error) => {
            console.error('Token verification failed:', error);
            setRedirect(true);
        });
    }, []);

    useEffect(() => {
        if (!usageResponse) {
            return;
        }
        setUserDataLoading(false);
    }, [usageResponse]);

    const [selectedUser, setSelectedUser] = useState('');

    const handleUserSelect = (userId: string) => {
        setSelectedUser(userId);
    };

    useEffect(() => {
        if (!selectedUser) {
            return;
        }
        if (!usageResponse || !usageResponse[selectedUser]) {
            setUserDataLoading(true);
            loadUsageItems(selectedUser, 1, setUsageResponse);
        }
    }, [selectedUser, usageResponse]);

    return (
        redirect ? <Navigate to={{ pathname: "/login" }}/> :
        users.length === 0 ? 
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <GradientCircularProgress />
            <Typography variant="h5" ml={3}>
                Loading users...
            </Typography>
        </Box>
        :
        <Container
            maxWidth={false}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'center',
                minHeight: '100vh',
                flex: 1,
            }}
            >
            {/* </Box> */}
            <Box sx={{ /*Extre border border: '2px solid',*/display: 'flex', width: '80%', flexDirection: 'row' }}>
                <Box sx={{ display: 'flex', flex: 2, flexDirection: 'column' }}>
                    <Box sx={{ /*Extre border border: '2px solid',*/ display: 'flex', justifyContent: 'center', pl: 2, pr: 2 }}>
                        {users.length > 0 &&
                            <UserList
                                users={users}
                                selectedUser={selectedUser}
                                onUserSelect={handleUserSelect}
                                handleLoadMore={() => setCurrentPageNum(currentPageNum + 1)}
                            />
                        }
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flex: 8, ml: 2}}>
                    {selectedUser && userDataLoading &&
                        <GradientCircularProgress />
                    }
                    {selectedUser && !userDataLoading && usageResponse && usageResponse[selectedUser] &&
                        <UsageData usages={usageResponse[selectedUser].usages} total_pages={usageResponse[selectedUser].total_pages} />
                    }
                    {!selectedUser &&
                        <Typography variant="h5" gutterBottom>
                            Select a user to view their data
                        </Typography>
                    }
                </Box>
            </Box>
        </Container>
    );
};