import React, { useEffect, useState } from 'react';
import ReactFlow, { Node, Edge, Background, Controls } from 'reactflow';
import 'reactflow/dist/style.css';
import { getUsageTree } from '../../api/helpers';

const NODE_WIDTH = 200;
const NODE_HEIGHT = 80;
const LEVEL_GAP = 150; 
const SIBLING_GAP = 100;

const UsageTree = () => {
  const [nodes, setNodes] = useState<Node[]>([]);
  const [edges, setEdges] = useState<Edge[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUsageTree = async () => {
      try {
        setLoading(true);
        const tree = await getUsageTree();
        const { treeNodes, treeEdges } = transformTreeToFlow(tree);
        setNodes(treeNodes);
        setEdges(treeEdges);
      } catch (err) {
        setError('Failed to load usage tree');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsageTree();
  }, []);

  const transformTreeToFlow = (tree: any) => {
    const treeNodes: Node[] = [];
    const treeEdges: Edge[] = [];

    const traverseTree = (node: any, parentId: string | null = null, level = 0, index = 0) => {
      const nodeId = node.id;
      const appearanceCount = node.appearance_count || 0;
      const nodePosition = {
        x: index * (NODE_WIDTH + SIBLING_GAP),
        y: level * LEVEL_GAP,
      };

      const nodeLabel = `${nodeId} (Count: ${appearanceCount})`;

      treeNodes.push({
        id: nodeId,
        data: { 
          label: (
            <div style={{ fontSize: '17px', fontWeight: 'bold' }}>
              {nodeLabel}
            </div>
          )
        },
        position: nodePosition,
        style: {
          width: NODE_WIDTH,
          height: NODE_HEIGHT,
        },
      });

      if (parentId) {
        treeEdges.push({
          id: `${parentId}-${nodeId}`,
          source: parentId,
          target: nodeId,
        });
      }

      node.children.forEach((child: any, childIndex: number) => {
        traverseTree(child, nodeId, level + 1, childIndex);
      });
    };

    traverseTree(tree);
    return { treeNodes, treeEdges };
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div style={{ height: '80vh', width: '100%' }}>
      <ReactFlow nodes={nodes} edges={edges} fitView>
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default UsageTree;
