import axios from 'axios';

// const baseURL = 'http://matlaber1.media.mit.edu:8000';
const baseURL = 'https://api.eon.health';

export const login = async (username: string, password: string) => {
    console.log('Logging in...', baseURL);
    try {
        const response = await axios.post(baseURL + '/analytics/login/', {
            username: username,
            password: password,
        });
        if (response.status !== 200) {
            return response.data.error;
        }
        localStorage.setItem('authToken', response.data.access);
        localStorage.setItem('refreshToken', response.data.refresh);
        return response.status;
    } catch (error: any) {
        console.log('Login failed:', error);
        return error.response.status;
    }
}

export const logout = async () => {
    try {
        const refreshToken = localStorage.getItem('refreshToken');
        if (refreshToken) {
            await axios.post(baseURL + '/analytics/logout/', {
                refresh: refreshToken,
            });
        }
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
    } catch (error) {
        console.log('Logout failed:', error);
    }
}

export const isAuthenticated = (): boolean => {
    const authToken = localStorage.getItem('authToken');
    return authToken !== null;
  };

export const verifyToken = async () => {
    try {
        const response = await axios.post(baseURL + '/analytics/token/verify/', {
            token: localStorage.getItem('authToken'),
        });
        if (response.status !== 200) {
            console.log('VerifyToken shouldn\'t happen because of the try-catch block', response);
            return false;
        }
        return true;
    } catch (error: any) {
        if (error.response && error.response.status === 401) {
            console.log('Token is expired. Refreshing token...');
            try {
                const refreshResponse: any = await refreshToken();
                if (refreshResponse.status !== 200) {
                    console.log('shouldn\'t happen because of the try-catch block', refreshResponse.status);
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('refreshToken');
                    return false;
                } else {
                    console.log('Token refreshed successfully');
                    return true;
                }
            } catch (error) {
                localStorage.removeItem('authToken');
                localStorage.removeItem('refreshToken');
                console.log('Token refresh failed:', error);
                return false;
            }
        } else {
            console.log('Token verification failed:', error);
            return false;
        }
    }
}

export const refreshToken = async () => {
    try {
        const response = await axios.post(baseURL + '/analytics/token/refresh/', {
            refresh: localStorage.getItem('refreshToken'),
        });
        localStorage.setItem('authToken', response.data.access);
        localStorage.setItem('refreshToken', response.data.refresh);
        return response;
    } catch (error) {
        console.error('Token refresh failed:', error);
        return error;
    }
};