import React, { useEffect, useState, useCallback } from "react";
import { tokens } from "../../Theme";
import { BarChart } from '@mui/x-charts/BarChart';
import { Button, Typography, Box, useTheme, ButtonGroup } from '@mui/material';
import { getDailyInfoUsageCountByTimeRange } from '../../api/helpers';

const chartSetting = {
  width: 1000,
  height: 500,
};

const valueFormatter = (value: number | null) => `${value}`;

export function DailyInfoUsage() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [data, setData] = useState([
    { screen: 'nutrition', count: 0 },
    { screen: 'recovery', count: 0 },
    { screen: 'movement', count: 0 },
    { screen: 'cognition', count: 0 },
    { screen: 'connection', count: 0 },
    { screen: 'aesthetics', count: 0 },
  ]);
  const [timeRange, setTimeRange] = useState('last_24_hours');

  const fetchScreenUsageCounts = useCallback(async () => {
    const usageCounts = await getDailyInfoUsageCountByTimeRange(timeRange);

    setData([
      { screen: 'nutrition', count: usageCounts?.nutrition || 0 },
      { screen: 'recovery', count: usageCounts?.recovery || 0 },
      { screen: 'movement', count: usageCounts?.movement || 0 },
      { screen: 'cognition', count: usageCounts?.cognition || 0 },
      { screen: 'connection', count: usageCounts?.connection || 0 },
      { screen: 'aesthetics', count: usageCounts?.aesthetics || 0 },
    ]);
  }, [timeRange]);

  useEffect(() => {
    fetchScreenUsageCounts();
  }, [timeRange, fetchScreenUsageCounts]);

  const handleButtonClick = (selectedTimeRange: string) => {
    setTimeRange(selectedTimeRange);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Typography variant="h3" align="center" gutterBottom>
        DAILY INFO USAGE ({timeRange.replace('_', ' ').replace('_', ' ').toUpperCase()})
      </Typography>
      <ButtonGroup variant="contained" aria-label="outlined primary button group"
        sx={{ backgroundColor: theme.palette.success.main, color: theme.palette.grey[100], mt: 2, mb: 2 }}>
        <Button
          onClick={() => handleButtonClick('last_24_hours')}
          sx={{
            backgroundColor: timeRange === 'last_24_hours' ? colors.greenAccent[500] : colors.greenAccent[600],
            color: colors.grey[100],
            '&:hover': {
              backgroundColor: colors.greenAccent[500],
            },
          }}
        >
          Last 24 Hours
        </Button>
        <Button
          onClick={() => handleButtonClick('this_week')}
          sx={{
            backgroundColor: timeRange === 'this_week' ? colors.greenAccent[500] : colors.greenAccent[600],
            color: colors.grey[100],
            '&:hover': {
              backgroundColor: colors.greenAccent[500],
            },
          }}
        >
          This Week
        </Button>
        <Button
          onClick={() => handleButtonClick('this_month')}
          sx={{
            backgroundColor: timeRange === 'this_month' ? colors.greenAccent[500] : colors.greenAccent[600],
            color: colors.grey[100],
            '&:hover': {
              backgroundColor: colors.greenAccent[500],
            },
          }}
        >
          This Month
        </Button>
      </ButtonGroup>
      <BarChart
        dataset={data}
        xAxis={[{ scaleType: 'band', dataKey: 'screen', label: 'SCREENS' }]}
        yAxis={[{ label: 'USERS' }]}
        series={[{ dataKey: 'count', label: 'USERS', valueFormatter }]}
        layout="vertical"
        {...chartSetting}
      />
    </Box>
  );
}

export default DailyInfoUsage;
