import React from 'react';
import { UserType } from '../Types';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Paper,
  Box,
  CardActions,
  Button,
} from '@mui/material';

interface UserListProps {
  users: UserType[];
  selectedUser: string;
  onUserSelect: (userId: string) => void;
  handleLoadMore: () => void;
}

const UserList: React.FC<UserListProps> = ({ users, selectedUser, onUserSelect, handleLoadMore }) => {
  return (
    <Box>
      <Typography variant="h6" align="center" gutterBottom>
        Users
      </Typography>
      <Divider />
      <Paper elevation={3} sx={{ flexGrow: 1 }}>
      <Box sx={{ height: '100%', overflow: 'auto' }}>
        <List>
          {Object.entries(users).map(([userId, userObj]) => (
            <ListItem
              key={userId}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'action.hover',
                },
                backgroundColor: selectedUser === userId ? 'action.selected' : 'inherit',
              }}
              onClick={() => onUserSelect(userObj.email)}
            >
              <ListItemText
                primary={
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {`${userObj.first_name} ${userObj.last_name}`}
                  </Typography>
                }
                secondary={userObj.email}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Paper>
    {/** Display a plus icon to load more users */}
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
      <CardActions>            
        <Button
          variant="contained"
          color="primary"
          onClick={handleLoadMore}
        >
          Load More
        </Button>
    </CardActions>
    </Box>
    </Box>
  );
};

export default UserList;