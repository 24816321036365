import React, { useEffect, useState,useCallback  } from 'react';
import ApexCharts from 'react-apexcharts';
import { getWeeklyUserStats } from '../api/helpers';
import { tokens } from "../Theme";
import { Button, Typography, Box, useTheme } from '@mui/material';

export function WeeklyUsersChart() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);  // Get colors based on the theme mode (light/dark)

  const [chartData, setChartData] = useState<any>({
    series: [],
    options: {
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        categories: [],
        labels: {
          style: {
            colors: colors.grey[100],  // Adjust label colors for x-axis
            fontSize: '12px',
          },
        },
        axisBorder: {
          color: colors.grey[300],  // Set x-axis border color
        },
        axisTicks: {
          color: colors.grey[300],  // Set x-axis tick color
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: colors.grey[100],  // Adjust label colors for y-axis
            fontSize: '12px',
          },
        },
        max: undefined,  // Will be dynamically set later
        axisBorder: {
          color: colors.grey[300],  // Set y-axis border color
        },
        axisTicks: {
          color: colors.grey[300],  // Set y-axis tick color
        },
      },
      tooltip: {
        theme: theme.palette.mode === 'dark' ? 'dark' : 'light',  // Adjust tooltip theme based on the mode
        x: {
          format: 'dd/MM/yy',
        },
      },
      grid: {
        borderColor: colors.grey[300],  // Set grid line color
      },
      title: {
        text: 'New Vs Returning Vs Total Users (Last 6 Weeks)',
        align: 'center',
        style: {
          fontSize: '18px',
          fontWeight: 'bold',
          color: colors.grey[100],  // Adjust title color based on the mode
        },
      },
    },
  });

  const [isDataLoaded, setIsDataLoaded] = useState(false);

  // Function to fetch the weekly user stats data
  const fetchData = useCallback(async () => {
    try {
      const response = await getWeeklyUserStats();
      
      const labels = response.weekly_data.map((item: any) => new Date(item.week_start).toISOString());
      const newUsers = response.weekly_data.map((item: any) => item.new_users_count);
      const returningUsers = response.weekly_data.map((item: any) => item.returning_users_count);
      const totalUsers = response.weekly_data.map((item: any) => item.total_users_count);  

      // Calculate the maximum Y-axis value with some padding
      const allUserCounts = [...newUsers, ...returningUsers, ...totalUsers];
      const maxYValue = Math.max(...allUserCounts) * 1.5;  // Adding padding to the maximum value

      setChartData({
        series: [
          {
            name: 'New Users',
            data: newUsers,
          },
          {
            name: 'Returning Users',
            data: returningUsers,
          },
          {
            name: 'Total Users',
            data: totalUsers,
          },
        ],
        options: {
          ...chartData.options,
          xaxis: {
            ...chartData.options.xaxis,
            categories: labels,
          },
          yaxis: {
            ...chartData.options.yaxis,
            max: maxYValue,
          },
        },
      });

      setIsDataLoaded(true);
    } catch (error) {
      console.error('Failed to fetch weekly user stats:', error);
    }
  }, []);

  // Fetch data on component mount and on theme change
  useEffect(() => {
    fetchData();
  }, [fetchData,theme.palette.mode]);  

  const handleRefresh = () => {
    setIsDataLoaded(false);  
    fetchData();             
  };

  return (
    <Box sx={{ padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)' }}>
      <Typography variant="h4" sx={{ color: colors.grey[100], textAlign: 'center', mb: 2 }}>
        Weekly User Stats
      </Typography>
      {isDataLoaded ? (
        <ApexCharts
          options={chartData.options}
          series={chartData.series}
          type="area"
          height={350}
        />
      ) : (
        <Typography sx={{ color: colors.redAccent[500], textAlign: 'center' }}>Loading chart...</Typography>
      )}
      <Button
        variant="contained"
        onClick={handleRefresh}  
        sx={{
          backgroundColor: colors.greenAccent[500],
          color: colors.grey[100],
          mt: 2,
          '&:hover': {
            backgroundColor: colors.greenAccent[600],
          },
        }}
      >
        Refresh Data
      </Button>
    </Box>
  );
}

export default WeeklyUsersChart;