import React from 'react';
import { UsageDataType, ViewsUsageObject } from '../Types';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  Divider,
  Box,
  CardActions,
} from '@mui/material';
import Button from '@mui/material/Button';

interface CardProps {
  timestampString: string;
  screenName: string;
  screenDetails: string;
  duration: string;  
}

const CardDisplay: React.FC<CardProps> = ({ timestampString, screenName, screenDetails, duration }) => {
    const localeTimestampStr = new Date(timestampString).toLocaleString();
    return (
        <React.Fragment>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {localeTimestampStr}
                </Typography>
                <Typography variant="h5" component="div">
                    {screenName}
                </Typography>
                <Typography variant="body2">
                    {screenDetails}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Duration: {duration}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="warning">
                    Learn More
                </Button>
            </CardActions>
        </React.Fragment>
    );
};

const description = 'Screen view information';
const UsageData: React.FC<UsageDataType> = ({ usages, total_pages }) => {
  const calculateDuration = (currentTime: string, nextTime: string | null): string => {
    if (!nextTime) return 'End of session'; 

    const currentTimestamp = new Date(currentTime).getTime();
    const nextTimestamp = new Date(nextTime).getTime();
    const timeDiff = currentTimestamp-nextTimestamp  ;

    if (timeDiff > 300000) { // 5 minutes in milliseconds
      return 'New session started';
    }

    
    const minutes = Math.floor(timeDiff / 60000);
    const seconds = Math.floor((timeDiff % 60000) / 1000);
    return `${minutes} min ${seconds} sec`;
  };

  return (
    <Box>
      <Typography variant="h6" align="center" gutterBottom>
        Usage Data
      </Typography>
      <Divider />
      <Grid container spacing={1} sx={{ mt: 2 }}>
        {usages.map((item, id) => {
          const usageScreenData = (item as ViewsUsageObject).screenData;
          let itemType, isTarget;
          if (usageScreenData) {
            const screenDataArray = usageScreenData.split('.');
            itemType = screenDataArray[1];
            isTarget = screenDataArray[2];
          }
          let screenName = item.currentScreen;
          screenName = itemType ? ' ' + itemType.charAt(0).toUpperCase() + itemType.slice(1) : screenName;
          screenName += isTarget && isTarget === 'true' ? ' Targets' : '';

          
          const nextItem = usages[id + 1];
          const duration = calculateDuration(item.timeStamp, nextItem ? nextItem.timeStamp : null);

          return (
            <Grid item xs={12} sm={6} md={4} key={id}>
              <Card
                sx={{
                  minWidth: 275,
                  '&:hover': {
                    bgcolor: 'primary.dark',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                    transform: 'translateY(-5px)',
                    transition: 'all 0.3s ease-in-out',
                  },
                }}
                variant="outlined"
              >
                <CardDisplay
                  timestampString={item.timeStamp}
                  screenName={screenName}
                  screenDetails={description}
                  duration={duration}  
                />
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default UsageData;