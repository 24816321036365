import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { LoginPage } from './screens/login';
import { DashboardPage } from './screens/pages/dashboard';
import { isAuthenticated, verifyToken } from './auth/helpers';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { ColorModeContext, useMode } from './Theme';
import Topbar from './screens/global/topbar';
import Sidebar from './screens/global/sidebar';
import { Users } from './screens/pages/Users';
import { DailyInfoUsage } from './screens/pages/DailyInfoUsage';
import { AllScreens } from './screens/pages/allScreens';
import { AccessFrequencyChart } from './screens/pages/UserFrequency';
import SearchUserUsage from './screens/pages/SearchUser';
import UsageTree from './screens/pages/UsageTree';

function App() {
  const [theme, colorMode] = useMode();
  const [auth, setAuth] = useState<boolean | null>(null); // Use null as initial state to indicate loading
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const authenticated = isAuthenticated();
      if (authenticated) {
        const tokenValid = await verifyToken();
        setAuth(tokenValid);
        if (!tokenValid) {
          navigate('/login', { replace: true });
        }
      } else {
        setAuth(false);
        navigate('/login', { replace: true });
      }
    };

    checkAuth();
  }, [navigate]);

  // Show loading state while checking authentication
  if (auth === null) {
    return <div>Loading...</div>;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/*"
            element={
              auth ? (
                <div className="app">
                  <Sidebar />
                  <main className="content">
                    <Topbar />
                    <Routes>
                      <Route path="/dashboard" element={<DashboardPage />} />
                      <Route path="/users" element={<Users />} />
                      <Route path="/allScreens" element={<AllScreens />} />
                      <Route path="/searchUser" element={<SearchUserUsage />} />
                      <Route path="/dailyInfoTrends" element={<DailyInfoUsage />} />
                      <Route path="/userFrequency" element={<AccessFrequencyChart />} />
                      <Route path="/usageTree" element={<UsageTree />} />
                      <Route path="*" element={<Navigate to="/dashboard" replace />} />
                    </Routes>
                  </main>
                </div>
              ) : (
                <Navigate to="/login" replace />
              )
            }
          />
        </Routes>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
