import React, { useState, useEffect } from 'react';
import { Box, Grid, Typography, Divider } from '@mui/material';
import { TotalCustomers } from './TotalCustomers';
import { getCurrentUserCount } from '../api/helpers';
import { getNewUsersCount } from '../api/helpers';
import PieActiveArc from './dashboardPieChart';
import ReturningUsers from './returningUsers';
import WeeklyUsersChart from './weeklyUserStats';

const Dashboard: React.FC = () => {
  const [userCount, setUserCount] = useState<number>(0);
  const [newUserCount, setNewUserCount] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserCounts = async () => {
      try {
        const currentUsers = await getCurrentUserCount();
        //console.log('Current Users:', currentUsers);
        const newUsers = await getNewUsersCount();
       // console.log('New Users:', newUsers);

        if (currentUsers && newUsers) {
          setUserCount(currentUsers.count);
          setNewUserCount(newUsers.newUsersCount);
        } else {
          setError('Failed to fetch user counts');
        }
      } catch (error) {
        //console.error('Error fetching user counts:', error);
        setError('Error fetching user counts');
      }
    };

    fetchUserCounts();
  }, []);

  const previousUserCount = userCount - newUserCount;
  const calculateTrendAndDiff = () => {
    //console.log("Previous Users:", previousUserCount);
    const diff = previousUserCount > 0 ? ((newUserCount / previousUserCount) * 100).toFixed(2) : "0";
    const trend: 'up' | 'down' = newUserCount > 0 ? 'up' : 'down';
    return { diff, trend };
  };

  const { diff, trend } = calculateTrendAndDiff();

  return (
    <Box>
      <Typography variant="h4" align="center" gutterBottom>
        Dashboard
      </Typography>
      {error && <Typography color="error">{error}</Typography>}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          <TotalCustomers value={userCount.toString()} trend={trend} diff={parseFloat(diff)} />
        </Grid>
  
      </Grid>
      <Divider sx={{ my: 3 }} />


      <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={6}>
          <PieActiveArc newUserCount={newUserCount} previousUserCount={previousUserCount} />
        </Grid>
      <Grid item xs={12} md={6} lg={6}>
          <ReturningUsers />
        </Grid>
        {/* Add more statistics or charts as needed */}
      </Grid>
      <Divider sx={{ my: 3 }} />
      <Grid>
        <WeeklyUsersChart />
        </Grid>
    </Box>
    
  );
};

export default Dashboard;
