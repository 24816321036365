import React, { useEffect, useRef, useState, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated, login, verifyToken } from '../auth/helpers';
import { Button, TextField, Typography, Box, Stack, useTheme, IconButton } from '@mui/material';
import { ColorModeContext, tokens } from "../Theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";

export const LoginPage = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [errorMessage, setErrorMessage] = useState('');
    const initialized = useRef(false);
    const [redirect, setRedirect] = useState(false);
    const colorMode = useContext(ColorModeContext);
    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const username = e.currentTarget.username.value;
        const password = e.currentTarget.password.value;

        try {
            const status = await login(username, password);
            console.log('Login status:', status);
            if (status === 200) {
                console.log('Login successful redirecting to /dashboard');
                setRedirect(true);
            } else {
                setErrorMessage('Invalid username or password.');
            }
        } catch (error) {
            console.log('Login failed:', error);
            setErrorMessage('An error occurred. Please try again.');
        }
    };

    useEffect(() => {
        if (initialized.current) {
            return;
        }
        initialized.current = true;

        const checkAuth = async () => {
            const authStatus = await isAuthenticated();
            if (authStatus) {
                const status = await verifyToken();
                if (status) {
                    setRedirect(true);
                }
            }
        };

        checkAuth();
    }, []);

    return redirect ? (
        <Navigate to="/dashboard" />
    ) : (
        <Box
            sx={{
                display: { xs: 'flex', lg: 'grid' },
                flexDirection: 'column',
                gridTemplateColumns: '1fr 1fr',
                minHeight: '100%',
            }}
        >
            <Box sx={{ display: 'flex', flex: '1 1 auto', flexDirection: 'column' }}>
                <Box sx={{ p: 3 }}>
                    <IconButton onClick={colorMode.toggleColorMode}>
                        {theme.palette.mode === "dark" ? (
                            <DarkModeOutlinedIcon />
                        ) : (
                            <LightModeOutlinedIcon />
                        )}
                    </IconButton>
                </Box>
                <Box sx={{ alignItems: 'center', display: 'flex', flex: '1 1 auto', justifyContent: 'center', p: 3 }}>
                    <Box sx={{ maxWidth: '450px', width: '100%' }}>
                        <Typography variant="h2" gutterBottom>
                            Sign in
                        </Typography>
                        <form onSubmit={handleLogin}>
                            <TextField
                                label="Username"
                                name="username"
                                fullWidth
                                margin="normal"
                                required
                            />
                            <TextField
                                label="Password"
                                name="password"
                                type="password"
                                fullWidth
                                margin="normal"
                                required
                            />
                            {errorMessage && (
                                <Typography color="error" align="center">
                                    {errorMessage}
                                </Typography>
                            )}
                            <Button type="submit" variant="contained" fullWidth sx={{ backgroundColor: colors.greenAccent[800], color: colors.grey[100], mt: 2,'&:hover': {
              backgroundColor: colors.greenAccent[500] ,
            }, }}>
                                Login
                            </Button>
                        </form>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    alignItems: 'center',
                    background: 'radial-gradient(50% 50% at 50% 50%, #122647 0%, #090E23 100%)',
                    color: 'white',
                    display: { xs: 'none', lg: 'flex' },
                    justifyContent: 'center',
                    p: 3,
                }}
            >
                <Stack spacing={3}>
                    <Stack spacing={1}>
                        <Typography color="white" sx={{ fontSize: '24px', lineHeight: '32px', textAlign: 'center' }} variant="h1">
                            Welcome to{' '}
                            <Box component="span" sx={{ color: '#15b79e' }}>
                                Eon.Health Analytics
                            </Box>
                        </Typography>
                        <Typography align="center" variant="subtitle1">
                            A professional healthcare analytics platform.
                        </Typography>
                    </Stack>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box
                            component="img"
                            alt="Analytics"
                            src="/EONlogo.png" // APP LOGO
                            sx={{ height: 'auto', width: '100%', maxWidth: '100px' }}
                        />
                    </Box>
                </Stack>
            </Box>
        </Box>
    );
};