import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { Box, Typography } from '@mui/material';

interface PieActiveArcProps {
  newUserCount: number;
  previousUserCount: number;
}

const PieActiveArc: React.FC<PieActiveArcProps> = ({ newUserCount, previousUserCount }) => {
  const data = [
    { id: 0, value: previousUserCount, label: 'Previous Users' },
    { id: 1, value: newUserCount, label: 'New Users' },
  ];

  return (
    <Box>
      <Typography variant= "h4">
        New vs Previous Users
      </Typography>
    <PieChart
    colors = {['#009596','#004B95',]}
      series={[
        {
          data,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 0, additionalRadius: -80, color: 'grey' },
          outerRadius: 135,
          innerRadius: 20,
        },
      ]}
      height={300}
      width={500}
    />
    </Box>
  );
};

export default PieActiveArc;
